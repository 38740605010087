import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import "./FuelSystemLines.css";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { useSelector } from "react-redux";
import { ProductService } from "../../services/product.service.ts";
import { useErrorHandler } from "../../services/errorhandler";

const FuelSystemLinesForm = ({ editData, onCancel, onSave }) => {
  const { profile } = useSelector((state) => state.auth);
  const errorHandler = useErrorHandler();
  const [ productList, setProductList ] = useState([])
  const [productValue, setProductValue] = useState(null);
  const initialValues = {
    client_id: editData?.client_id,
    site_id: editData?.site_id,
    Date: "",
    Tank_Number: "",
    Sales: "",
    Deliveries: "",
    Actual_Ending_Inventory_Volume: "",
    Beginning_Inventory_Volume: "",
    variance: "",
    book_value: "",
    Store_Number: 27531,
    // Porduct:""
  };
  const getProducts = () => {
    const payload = {
      client_id: profile.client,
      site_id: profile.sites,
    };
    ProductService.getProducts(payload)
      .pipe(errorHandler("Get Product Categories"))
      .subscribe((response) => {
        if (response?.data) {
          setProductList(response?.data || []);
        }
      });
  };
  useEffect(()=>{
    getProducts()
  },[])
  const validationSchema = Yup.object({
    Date: Yup.string().required("Date is required"),
    Tank_Number: Yup.string().required("Tank Number is required"),
    Sales: Yup.string().required("Sales is required"),
    Deliveries: Yup.string().required("Deliveries is required"),
    Actual_Ending_Inventory_Volume: Yup.string().required(
      "Actual Ending Inventory Volume is required"
    ),
    Beginning_Inventory_Volume: Yup.string().required(
      "Beginning Inventory Volume is required"
    ),
    variance: Yup.string().required("variance is required"),
    book_value: Yup.string().required("book value is required"),
  });

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    onSave({...values,Porduct:productValue[0]?.product_category_name});
    // console.log({...values,Porduct:productValue[0]?.product_category_name})
  };

  const FormikCalendar = ({ field, form, ...props }) => {
    return (
      <Calendar
        id={field.name}
        value={field.value}
        onChange={(e) => form.setFieldValue(field.name, e.value)}
        onBlur={() => form.setFieldTouched(field.name)}
        {...props}
      />
    );
  };

console.log(productValue)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="fuel-lines-form container py-4 px-5">
          <Field type="hidden" name="_id" />
         
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="Date">Date</label>
            <div>
              <Field
                name="Date"
                component={FormikCalendar}
                dateFormat="mm/dd/yy"
                placeholder="Select a date"
              />
              <ErrorMessage
                name="Date"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="Tank_Number">Tank Number</label>
            <div>
              <Field type="text" name="Tank_Number" />
              <ErrorMessage
                name="Tank_Number"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="Sales">Sales</label>
            <div>
              <Field type="text" name="Sales" />
              <ErrorMessage
                name="Sales"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="Deliveries">Deliveries</label>
            <div>
              <Field type="text" name="Deliveries" />
              <ErrorMessage
                name="Deliveries"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="Actual_Ending_Inventory_Volume">
              Actual Ending Inventory Volume
            </label>
            <div>
              <Field type="text" name="Actual_Ending_Inventory_Volume" />
              <ErrorMessage
                name="Actual_Ending_Inventory_Volume"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="Beginning_Inventory_Volume">
              Beginning Inventory Volume
            </label>
            <div>
              <Field type="text" name="Beginning_Inventory_Volume" />
              <ErrorMessage
                name="Beginning_Inventory_Volume"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="book_value">Book value</label>
            <div>
              <Field type="text" name="book_value" />
              <ErrorMessage
                name="book_value"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center ">
          <label htmlFor="book_value">Product Category</label>
        <div style={{width:"238px"}}>

          <MultiSelect
            value={productValue}
            style={{width:"238px"}}
            options={productList}
            optionLabel="product_category_name"
            onChange={(e) => setProductValue(e.value)}
            placeholder="Product Category"
            showSelectAll={true}
            selectAllLabel={"Select all"}
          />
          </div>
        </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="variance">Variance</label>
            <div>
              <Field type="text" name="variance" />
              <ErrorMessage
                name="variance"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="form-btns d-flex justify-content-end gap-3 pt-3">
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
            <Button type="button" severity="danger" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FuelSystemLinesForm;
