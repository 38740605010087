import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { ANALYTICS_URL, PRODUCT_URLS } from "./apiUrls.ts";
export const AnalyticsService = {
  getSites: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(ANALYTICS_URL.GET_SITES, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getStates: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(ANALYTICS_URL.GET_STATES, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getSalesAndDeliveries: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(PRODUCT_URLS.GET_LIST, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getSales: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(PRODUCT_URLS.SALES, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getCumulativeSales: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(PRODUCT_URLS.CUMULATIVESALES, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
};
