import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import FuelSystemLinesDetailTab from "./FuelSystemLinesDetailTab";
import FuelSystemLinesReadings from "./FuelSystemLinesReadings";
import { AnalyticsService } from "../../services/analytics.service.ts";
import { useErrorHandler } from "../../services/errorhandler";
import { useSelector } from "react-redux";

const FuelSystemLines = () => {
  const errorHandler = useErrorHandler();
  const { profile } = useSelector((state) => state.auth);
  const [sites, setSites] = useState([]);
  useEffect(() => {
    getSiteDetails();
  }, [])
  const getSiteDetails = () => {
    const payload = {
      site_id: profile.sites,
      client_id: profile.client,
    };

    AnalyticsService.getSites(payload)
      .pipe(errorHandler("Get Sites"))
      .subscribe((response) => {
        if (response?.data) {
          const facilityOptions = response?.data.map((facility) => ({
            label: facility.facility_name,
            value: facility.site_id,
            state: facility.state,
          }));
          setSites(facilityOptions);
        }
      });
  };
  
  return (
    <div className="card h-100 w-100">
      <TabView className="FuelSystemLinesTabView">
        <TabPanel header="Fuel System readings">
          <FuelSystemLinesReadings sites={sites}/>
        </TabPanel>
        <TabPanel header="Fuel System Lines Details">
          <FuelSystemLinesDetailTab sites={sites} />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default FuelSystemLines;
