export enum DASHBOARD_URLS {

}

export enum LOGIN_URLS {
    LOGIN = "login/",
    PROFILE = "profile/",
    REGISTER = "register/"
}

export enum FUEL_MAPPING_URLS {
    GET_LIST = "get_fuel_system/",
    CREATE = "create_fuel_system/",
    CREATENEW = "fuel_reading_update/",
    DELETE = "delete_fuel_reading/",
    CALCULATE = "calculate_variance/",
    // UPDATE = "update_fuel_system/",
    UPDATE = "update_fuel_system/",
    IMPORT = "bulk_upload/",
    Group = 'iv_group_report/',
    GetReadings = 'fuel_reading/'
}
export enum FUEL_SYSTEM_URLS{
    UPDATE="update_variance/"
}

export enum ANALYTICS_URL {
    GET_SITES = "search_site_name/",
    GET_STATES = "search_state/"
}

export enum REPORTING_URL {
    GET_EXPORT = "export_state_report/",
    // GET_STATES = "search_state/"
}
export enum PRODUCT_URLS {
    GET_LIST = "product_category_details/",
    CREATE = "create_product_category/",
    UPDATE = "update_product_category/",
    SALES="search_date_range/",
    CUMULATIVESALES="cummilative_sum/"
}


export enum ROLES_URLS {
    GET_USERS = "list_users/",
    GET_PERMISSIONS = "list_permissions/",
    GET_ROLE_PERMISSIONS = "list_roles/",
    UPDATE_USER = "update_user/",
    CREATE_PERMISSION = "create_permissions/",
    UPDATE_PERMISSION = "update_permissions/",
    CREATE_ROLE = "create_role/",
    UPDATE_ROLE ="update_roles/",
    Group = 'iv_group_report/'
}