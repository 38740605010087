
import { useDispatch } from 'react-redux';
import { loaderModel } from '../store/slices/loaderSlice';


export const useCommonService = () => {
    const dispatch = useDispatch();

    const startLoading = () => {
        console.log('hello world')
        dispatch(loaderModel.actions.startLoading({
            isLoading: true
        }));
    };

    const stopLoading = () => {
        dispatch(loaderModel.actions.stopLoading({
            isLoading: false
        }));
    };

    return { startLoading, stopLoading };
};

