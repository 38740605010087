import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { FUEL_MAPPING_URLS, FUEL_SYSTEM_URLS } from "./apiUrls.ts";

export const FuelMappingService = {
  getFuelMapping: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.GET_LIST, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
 
  create: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.CREATE, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  createNew: (body: any) => {
    console.log(body)
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.CREATENEW, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  delete: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.DELETE, "DELETE", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  update: (id: number, body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      const url = `${FUEL_MAPPING_URLS.UPDATE}`;
      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  fuelsystemupdate: (id: number, body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      const url = `${FUEL_SYSTEM_URLS.UPDATE}`;
      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  calculate: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      const url = `${FUEL_MAPPING_URLS.CALCULATE}`;
      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  import: (formdata: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.IMPORT, "POST", formdata, {
        'Content-Type': 'multipart/form-data',
      }).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },

}
