import React, { useState } from 'react';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import "./FuelSystemReadingsImport.css";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

const SUPPORTED_FORMATS = ['text/csv'];

const validationSchema = Yup.object().shape({
  site_id: Yup.string().required('Site Name is required'),
  file: Yup.mixed()
    .required('A CSV file is required')
    .test(
      'fileFormat',
      'Only CSV files are allowed',
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});
const FormikDropdown = ({ field, form, options }) => {
  return (
      <Dropdown
          id={field.name}
          value={field.value}
          loading={!options?.length}
          options={options}
          onChange={(e) => form.setFieldValue(field.name, e?.value)}
          onBlur={() => form.setFieldTouched(field.name)}
          placeholder={"Select a site"}
          optionLabel="label"
          style={{width: "19em"}}
      />
  );
};

const FuelSystemReadingsImport = ({onCancel, onSubmit, sites}) => {
    const [fileName, setFileName] = useState("");
  return (
    <Formik
      initialValues={{
        site_id: sites[0]?.site_id,
        file: null,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, isSubmitting, values }) => (
        <Form className='import-form p-4'>
          <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="site_id" style={{width: "6em"}}>Site Name</label>
                        <div>
                            <Field
                                name="site_id"
                                component={FormikDropdown}
                                options={sites}
                            />
                            <ErrorMessage name="site_id" component="div" 
                            style={{fontSize: "10px",
                              color: "red",
                              marginTop: "0.2rem",}} />
                        </div>
                    </div>
          <div className='position-relative mb-4'>
            <label htmlFor="file" className='me-3'>Upload File</label>
            <input
              id="file"
              name="file"
              type="file"
              accept=".csv"
              onChange={(event) => {
                const file = event.target.files[0];
                if(file){
                    setFieldValue('file', file);
                    setFileName(file?.name || "");
                }
              }}
            />
            <div className='form-input'>{fileName}</div>
            <ErrorMessage name="file" component="div" className='upload-error' />
          </div>

          <div className='form-btns d-flex justify-content-end gap-3 pt-3'>
            <Button type="submit" disabled={isSubmitting}>
                Save
            </Button>
            <Button type="button" severity="danger" onClick={onCancel}>
                Cancel
            </Button>
        </div>
        </Form>
      )}
    </Formik>
  );
};

export default FuelSystemReadingsImport;
