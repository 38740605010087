import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { PRODUCT_URLS } from "./apiUrls.ts";

export const ProductService = {
  getProducts: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(PRODUCT_URLS.GET_LIST, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  create: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(PRODUCT_URLS.CREATE, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  update: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(PRODUCT_URLS.UPDATE, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
}