import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false
};

const loaderModel = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        startLoading: (state, action) => {
            console.log("isLoading starting")
            state.isLoading = true;
        },
        stopLoading: (state,action) => {
              console.log("isLoading Stopping")
            state.isLoading = false;
        }
    }
})

export { loaderModel };
export default loaderModel.reducer;