import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Column } from "primereact/column";
import "./FuelSystemLines.css";
import { fuel, statusList } from "./data";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import FuelSystemLinesForm from "./FuelSystemLinesForm";
import { Dialog } from "primereact/dialog";
import { useErrorHandler } from "../../services/errorhandler";
import { RolesService } from "../../services/roles.service.ts";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsService } from "../../services/analytics.service.ts";
import { Toast } from "primereact/toast";
import { FuelMappingService } from "../../services/fuelSystem.service.ts";
import { slice } from "../../store/slices/auth.js";
import { useCommonService } from "../../services/useCommonService.ts";

const FuelSystemLinesDetailTab = ({sites}) => {
    const dispatch = useDispatch();
    const commonService = useCommonService();
    const { isLoading } = useSelector((state) => state?.loader || false);
  const { profile } = useSelector((state) => state.auth);
  // const [selectedProducts, setSelectedProducts] = useState({});
  const [formData, setFormData] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const errorHandler = useErrorHandler();
  const [fuelMapping, setFuelMapping] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [filteredData, setFilteredData] = useState(fuelMapping);
  const [filters, setFilters] = useState({ dates: null });
  const [statusValue, setStatuesValue] = useState("All");
  const [maxWidth, setMaxWidth] = useState(window.innerWidth - 250);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMaxWidth(window.innerWidth - 250);
      } else {
        setMaxWidth(window.innerWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getFuelMapping();
  }, []);

  
  const getFuelMapping = () => {
    const payload = {
      client_id: profile.client,
      site_id: profile.sites,
    };
    commonService.startLoading();
    RolesService.getFuelLineData(payload)
      .pipe(errorHandler("Get Fuel Mapping"))
      .subscribe((response) => {
        if (response?.data) {
          const data = response?.data?.map((item) => {
            item.id = Math.random().toString(36).substr(2, 9);
            return item;
          });
          setFuelMapping(data);
          setFilteredData(data);
        }
        commonService.stopLoading();
      });
  };
  const createNewReading = (data) => {
    console.log("Creating new reading", data);
    const payload = {
      site_id: profile.sites,
      client_id: profile.client,
    };
  };
  const callDeleteForArray = (data) => {
    const payload = {
      inventory_variance_id: data,
    };

    FuelMappingService.delete(payload)
      .pipe(errorHandler("Delete Reading"))
      .subscribe((response) => {
        if (response?.data) {
          console.log("delete Reading", response, response.data?.message);
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Deleted Successfully",
            })
          );
          refreshTableAlongWithFilter();
        }
      });
  };
  const handleReadingDeleteClick = (rowData) => {
    callDeleteForArray([rowData?._id]);
  };
  const handleBulkDeleteBySite = (selectedReadingsOfSite) => {
    const ids = selectedReadingsOfSite.map((item) => item._id);
    ids?.length && callDeleteForArray(ids);
  };
  const onReadingEditComplete = (data) => {
    const payload = [
      {
        _id: data?.newData?._id,
        Beginning_Inventory_Volume: data?.newData?.Beginning_Inventory_Volume,
        Actual_Ending_Inventory_Volume:
          data?.newData?.Actual_Ending_Inventory_Volume,
        Sales: data?.newData?.Sales,
        Deliveries: data?.newData?.Deliveries,
      },
    ];

    FuelMappingService.update(data?.newData?._id, payload)
      .pipe(errorHandler("Update Reading"))
      .subscribe((response) => {
        if (response?.data) {
          console.log("Updated Reading", response);
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Updated Successfully",
            })
          );
          refreshTableAlongWithFilter();
        }
      });
  };
  const uniqueStates = [...new Set(sites.map((facility) => facility.state))];
  const stateOptions = uniqueStates.map((state) => ({
    label: state,
    value: state,
  }));
  const numberEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
      />
    );
  };
  const actionColumn = (rowData, columnProps) => {
    return columnProps.rowEditor.editing ? (
      <>
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-text p-button-success"
          onClick={columnProps.rowEditor.onSaveClick}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={columnProps.rowEditor.onCancelClick}
        />
      </>
    ) : (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-secondary"
          onClick={columnProps.rowEditor.onInitClick}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={() => handleReadingDeleteClick(rowData)}
        />
      </>
    );
  };
  function replaceCommasWithSpaces(input) {
    if (typeof input === "string") {
      return input.split(",").join(" ");
    }
    return input;
  }
  const exportCSV = () => {
    const csvContent = [];
    filteredData?.forEach((parentRow) => {
      csvContent.push([
        "Client Name",
        "Site Name",
        "State",
        "Fuel System Name",
        "Period Start",
        "Period End",
        "Last Reading",
        "Next Reading",
        "Periodic Sales",
        "Periodic Deliverables",
        "Over/Under",
        "Leak Check Status",
      ]);
      csvContent.push([
        parentRow?.parent.client_name,
        parentRow?.parent.facility_name,
        parentRow?.parent.state,
        parentRow?.parent.Fule_System_Name,
        formatDate(parentRow?.parent.Period_Start),
        formatDate(parentRow?.parent.Period_End),
        formatDate(parentRow?.parent.Last_Reading),
        formatDate(parentRow?.parent.Next_Reading),
        formatNumber(parentRow?.parent.Periodic_Sales),
        formatNumber(parentRow?.parent.Periodic_Deliveries),
        formatNumber(parentRow?.parent.Over_Under),
        parentRow?.parent.result,
      ]);
      csvContent.push([
        "",
        "Tank Number",
        "Product",
        "Date",
        "Beginning Inventory Volume",
        "Actual Ending Inventory Volume",
        "Sales",
        "Deliveries",
        "Book Value",
        "Variance",
        "Cumulative Variance",
      ]);
      parentRow.parent.child.forEach((childRow) => {
        csvContent.push([
          "",
          replaceCommasWithSpaces(childRow.Tank_Number),
          childRow.Product,
          formatDate(childRow.only_date),
          formatDate(childRow.end_date),
          childRow.Beginning_Inventory_Volume,
          childRow.Actual_Ending_Inventory_Volume,
          formatNumber(childRow.Sales),
          formatNumber(childRow.Deliveries),
          formatNumber(childRow.book_value),
          formatNumber(childRow.variance),
          formatNumber(childRow.Cumsum),
        ]);
      });
      csvContent.push([]);
    });

    const csvString = csvContent.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "fuel-system-lines.csv";
    link.click();
  };
  const headerElement = (
    <div className="fw-semibold mx-4 mt-3">{`Create Fuel System Lines`}</div>
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${year}/${month}/${day}`;
  };
  const formatNumber = (value) => {
    let newVal = value;
    if (typeof value !== "number") {
      newVal = Number(value);
    }
    return newVal ? newVal?.toFixed(2) : "0.00";
  };
  const GetFilteredByParameters = (value) => {
    let filteredData = [...value];

    if (selectedFacilities?.length) {
      filteredData = filteredData.filter((item) =>
        selectedFacilities.includes(item?.parent?.site_id)
      );
    }
    if (selectedStates?.length) {
      filteredData = filteredData.filter((item) =>
        selectedStates.includes(item?.parent?.state)
      );
    }
    if (statusValue) {
      if (statusValue != "All") {
        filteredData = filteredData.filter(
          (item) =>
            statusValue?.toLowerCase() == item?.parent?.result?.toLowerCase()
        );
      }
    }
    return filteredData;
  };
  const onFilter = () => {
    if (
      filters.dates?.length !== 2 &&
      !selectedFacilities?.length &&
      !selectedStates?.length &&
      !statusValue
    ) {
      setFilteredData(fuelMapping);
      return;
    }
    if (filters.dates && filters.dates.length === 2) {
      const payload = {
        client_id: profile.client,
        site_id: profile.sites,
        start_date: formatDate(filters.dates[0]),
        end_date: formatDate(filters.dates[1]),
      };
      commonService.startLoading();
      RolesService.getFuelLineData(payload)
        .pipe(errorHandler("Get Fuel Mapping"))
        .subscribe((response) => {
          if (response?.data) {
            const data = response?.data?.map((item) => {
              item.id = Math.random().toString(36).substr(2, 9);
              return item;
            });
            setFuelMapping(data);
            const newData = GetFilteredByParameters(data);
            setFilteredData(newData);
          }
          commonService.stopLoading();
        });
      return;
    } else {
      const newData = GetFilteredByParameters(fuelMapping);
      setFilteredData(newData);
    }

    // if (statusValue?.length) {
    //   filtered = filtered.filter(item => (statusValue?.includes(item?.parent?.site_id)));
    // }

    // if (filters.dates && filters.dates.length === 2) {
    //   const [startDate, endDate] = filters.dates;
    //   filtered = filtered.filter(row => {
    //     const periodStart = new Date(row.parent.Period_Start);
    //     const periodEnd = new Date(row.parent.Period_End);

    //     return (
    //       (periodStart >= startDate && periodStart <= endDate) ||
    //       (periodEnd >= startDate && periodEnd <= endDate)
    //     );
    //   });
    // }
  };
  const refreshTableAlongWithFilter = () => {
    let myPayload = {
      client_id: profile.client,
      site_id: profile.sites,
    };
    if (filters.dates && filters.dates.length === 2) {
      myPayload = {
        client_id: profile.client,
        site_id: profile.sites,
        start_date: formatDate(filters.dates[0]),
        end_date: formatDate(filters.dates[1]),
      };
    }
    commonService.startLoading()
    RolesService.getFuelLineData(myPayload)
      .pipe(errorHandler("Get Fuel Mapping"))
      .subscribe((response) => {
        if (response?.data) {
          const data = response?.data?.map((item) => {
            item.id = Math.random().toString(36).substr(2, 9);
            return item;
          });
          setFuelMapping(data);
          const newData = GetFilteredByParameters(data);
          setFilteredData(newData);
        }
        commonService.stopLoading()
      });
  };
  const rowExpansionTemplate = (data) => {
    return (
      <div>
        {/* <div className="d-flex flex-wrap justify-content-end grid_buttons gap-2">
          <Button label="New Reading" text onClick={() => setFormData({})} />
          <Button
            label="Delete"
            text
            className="delete-button"
            disabled={
              !selectedProducts[data.id]?.length ||
              selectedProducts[data.id]?.length < 2
            }
            onClick={() => handleBulkDeleteBySite(selectedProducts[data.id])}
          />
        </div> */}
        <DataTable
          // selectionMode={"checkbox"}
          value={data.parent.child || []}
          id="fuel-lines-grid"
          dataKey={data._id}
          // selection={selectedProducts[data.id]}
          showSelectAll={false}
          editMode="row"
          // onRowEditComplete={onReadingEditComplete}
          scrollable
          scrollHeight={"250px"}
          // onSelectionChange={(e) =>
          //   setSelectedProducts({ ...selectedProducts, [data.id]: e.value })
          // }
          className="child-fuel-grid rounded border flex-grow-1"
        >
          {/* <Column
            selectionMode="multiple"
            headerStyle={{ width: "2rem" }}
            header={"Selected"}
          ></Column> */}
          <Column field="Tank_Number" header="Tank Number" />
          <Column field="Product" header="Product" />
          <Column field="only_date" header="Date" />
          {/* <Column field="end_date" header="End Date" /> */}
          <Column
            field="Beginning_Inventory_Volume"
            header="Beginning Inventory Volume"
            editor={numberEditor}
          ></Column>
          <Column
            field="Actual_Ending_Inventory_Volume"
            header="Actual Ending Inventory Volume"
            editor={numberEditor}
          ></Column>
          <Column
            field="Sales"
            header="Sales"
            body={(rowData) => formatNumber(rowData.Sales)}
            editor={numberEditor}
          ></Column>
          <Column
            field="Deliveries"
            header="Deliveries"
            editor={numberEditor}
            body={(rowData) => formatNumber(rowData.Deliveries)}
          ></Column>
          <Column
            field="book_value"
            body={(rowData) => formatNumber(rowData.book_value)}
            header="Book Value"
          />
          <Column
            field="variance"
            body={(rowData) => formatNumber(rowData.variance)}
            header="Variance"
          />
          <Column
            field="Cumsum"
            body={(rowData) => formatNumber(rowData.Cumsum)}
            header="Cumulative Variance"
          />
          {/* <Column
            rowEditor
            header={"Actions"}
            align={"center"}
            body={actionColumn}
          /> */}
        </DataTable>
      </div>
    );
  };
  const onRowToggle = (e) => {
    if (expandedRow && expandedRow.id == e.data.id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(e.data);
    }
  };
  const allowExpansion = (rowData) => {
    return rowData.parent.child.length > 0;
  };
    return (
        <div className="d-flex flex-column h-100 p-3 gap-3">
          {/* <div className="fuel-title">Fuel System Lines</div> */}
          <div className="d-flex align-items-end flex-column flex-sm-row">
            <div className="d-flex flex-row col-12 col-sm-8 flex-grow-1 flex-wrap gap-3">
              <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
                <label htmlFor="site">Site Name</label>
                <MultiSelect
                  value={selectedFacilities}
                  options={sites}
                  loading={!sites.length}
                  optionLabel="label"
                  onChange={(e) => setSelectedFacilities(e.value)}
                  placeholder="Select Facilities"
                />
              </div>
              <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
                <label htmlFor="state">State</label>
                <MultiSelect
                  value={selectedStates}
                  options={stateOptions}
                  onChange={(e) => setSelectedStates(e.value)}
                  placeholder="Select States"
                />
              </div>
              <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
                <label htmlFor="date">Date Range</label>
                <Calendar
                  value={filters.dates}
                  onChange={({ target: { name, value } }) =>
                    setFilters({ ...filters, [name]: value })
                  }
                  onHide={() =>
                    (!filters.dates?.[0] || !filters.dates?.[1]) &&
                    setFilters({ ...filters, dates: null })
                  }
                  selectionMode="range"
                  readOnlyInput
                  hideOnRangeSelection
                  placeholder="Date range"
                  id="dates"
                  name="dates"
                  showButtonBar
                />
              </div>
              <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
                <label htmlFor="status">Status</label>
                <Dropdown
                  value={statusValue}
                  onChange={(e) => setStatuesValue(e?.value)}
                  options={statusList}
                  optionLabel="name"
                  placeholder="Status"
                  optionValue="name"
                  id="status"
                  name="status"
                />
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-end grid_buttons gap-1">
              <Button label="Filter" onClick={onFilter} text />
              <Button label="Export" text onClick={exportCSV} />
            </div>
          </div>
          <Dialog
            appendTo="self"
            visible={!!formData}
            modal
            header={headerElement}
            onHide={() => setFormData(null)}
          >
            <FuelSystemLinesForm
              editData={formData}
              onSave={(data) => createNewReading(data)}
              onCancel={() => setFormData(null)}
            />
          </Dialog>
          <Toast ref={toast} />
          <div style={{ maxWidth: maxWidth }}>
            <DataTable
              id="fuel-lines-grid"
              ref={dt}
              dataKey="parent.id"
              showGridlines
              scrollable
              loading={isLoading}
              scrollHeight={window.innerHeight - 220}
              value={filteredData}
              size="large"
              className="fuel-system-grid rounded border flex-grow-1"
              rows={10}
              paginator
              totalRecords={fuelMapping.length}
              pageLinkSize={10}
              rowsPerPageOptions={[10, 20, 50]}
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              reorderableColumns
              resizableColumns
              rowExpansionTemplate={rowExpansionTemplate}
              expandedRows={[expandedRow]}
              onRowExpand={onRowToggle}
              onRowCollapse={onRowToggle}
              emptyMessage="No records found."
            >
              <Column expander={allowExpansion} style={{ width: "3rem" }} />
              <Column field="parent.client_name" header="Client Name" />
              <Column field="parent.facility_name" header="Site Name" />
              <Column field="parent.state" header="State" />
              <Column field="parent.Fule_System_Name" header="Fuel System Name" />
              <Column
                field="parent.Period_Start"
                body={(rowData) => formatDate(rowData?.parent?.Period_Start)}
                header="Period Start"
              />
              <Column
                field="parent.Period_End"
                body={(rowData) => formatDate(rowData?.parent?.Period_End)}
                header="Period End"
              />
              <Column
                field="parent.Last_Reading"
                body={(rowData) => formatDate(rowData?.parent?.Last_Reading)}
                header="Last Reading"
              />
              <Column
                field="parent.Next_Reading"
                body={(rowData) => formatDate(rowData?.parent?.Next_Reading)}
                header="Next Reading"
              />
              <Column
                field="parent.Periodic_Sales"
                body={(rowData) => formatNumber(rowData?.parent.Periodic_Sales)}
                header="Periodic Sales"
              />
              <Column
                field="parent.Periodic_Deliveries"
                body={(rowData) =>
                  formatNumber(rowData?.parent.Periodic_Deliveries)
                }
                header="Periodic Deliverables"
              />
              <Column
                field="parent.Over_Under"
                body={(rowData) => formatNumber(rowData?.parent?.Over_Under)}
                header="Over/Under"
              />
              <Column field="parent.result" header="Leak Check Status" />
            </DataTable>
          </div>
        </div>
      );
}

export default FuelSystemLinesDetailTab