import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Column } from "primereact/column";
import "./FuelSystemReadingsImport.css";
import { fuel, statusList } from "./data";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import FuelSystemLinesForm from "./FuelSystemLinesForm";
import { Dialog } from "primereact/dialog";
import { useErrorHandler } from "../../services/errorhandler";
import { RolesService } from "../../services/roles.service.ts";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsService } from "../../services/analytics.service.ts";
import { Toast } from "primereact/toast";
import { FuelMappingService } from "../../services/fuelSystem.service.ts";
import { slice } from "../../store/slices/auth.js";
import { useCommonService } from "../../services/useCommonService.ts";
import FuelSystemImport from "../FuelSystemMapping/FuelSystemImport.js";
import FuelSystemReadingsImport from "./FuelSystemReadingsImport.js";
import CalculateVarianceDialog from "./CalculateVarianceDialog.js";

const FuelSystemLinesReadings = ({ sites }) => {
  const dispatch = useDispatch();
  const commonService = useCommonService();
  const { isLoading } = useSelector((state) => state?.loader || false);
  const { profile } = useSelector((state) => state.auth);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [formData, setFormData] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const errorHandler = useErrorHandler();
  const [fuelMapping, setFuelMapping] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [filteredData, setFilteredData] = useState(fuelMapping);
  const [filters, setFilters] = useState({ dates: null });
  const [statusValue, setStatuesValue] = useState("All");
  const [maxWidth, setMaxWidth] = useState(window.innerWidth - 250);
  const [importDialog, setImportDialog] = useState(false);
  const [calculateVarianceDialog, setCalculateVarianceDialog] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMaxWidth(window.innerWidth - 250);
      } else {
        setMaxWidth(window.innerWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getFuelMapping();
  }, []);

  const getFuelMapping = () => {
    const payload = {
      client_id: profile.client,
      site_id: profile.sites,
    };
    commonService.startLoading();
    RolesService.getFuelLineReadings(payload)
      .pipe(errorHandler("Get Fuel Mapping"))
      .subscribe((response) => {
        if (response?.data) {
          const data = FormateAPIData(response?.data);
          setFuelMapping(data);
          setFilteredData(data);
        }
        commonService.stopLoading();
      });
  };
  const FormateAPIData = (response) => {
    const formattedData = Object.values(
      response.reduce((acc, item) => {
        const { site_id } = item;
        if (!acc[site_id]) {
          acc[site_id] = {
            parent: {
              site_id,
              id: Math.random().toString(36).substr(2, 9),
              child: [],
            },
          };
        }
        acc[site_id].parent.child.push(item);
        return acc;
      }, {})
    );
    // let modifiedObj = {};

    // for (let key in formattedData) {
    //   let newKey = getNameByCode(key);
    //   modifiedObj[newKey] = formattedData[key];
    // }

    return formattedData;
  };
  const getNameByCode = (code) => {
    const site = sites?.find((site) => site.value === code);
    return site ? site.label : code;
  };
  const createNewReading = (data) => {
    console.log(data)
    const site = sites?.find((site) => site.value === data?.site_id).label.split('-')[1];
    const payload = {
      client_id: profile.client,
      Store_Number:site,
      site_id: data?.site_id,
      Product:data?.Porduct,
      Date: formatDatePickerDate(data?.Date),
      Tank_Number: data?.Tank_Number,
      Sales: Number(data?.Sales),
      Deliveries: Number(data?.Deliveries),
      Actual_Ending_Inventory_Volume: Number(data?.Actual_Ending_Inventory_Volume),
      Beginning_Inventory_Volume: Number(data?.Beginning_Inventory_Volume),
      variance: Number(data?.variance),
      book_value: Number(data?.book_value),
    };
    commonService.startLoading();
    FuelMappingService.createNew(payload)
      .pipe(errorHandler("Creating new reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "new reading created Successfully",
            })
          );
          setFormData(null)
          refreshTableAlongWithFilter();
          commonService.stopLoading();
        }
      });
  };
  const callDeleteForArray = (data) => {
    const payload = {
      inventory_variance_id: data,
    };
    commonService.startLoading();
    FuelMappingService.delete(payload)
      .pipe(errorHandler("Delete Reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Deleted Successfully",
            })
          );
          refreshTableAlongWithFilter();
          commonService.stopLoading();
        }
      });
  };
  const handleReadingDeleteClick = (rowData) => {
    callDeleteForArray([rowData?._id]);
  };
  const handleBulkDeleteBySite = (selectedReadingsOfSite) => {
    const ids = selectedReadingsOfSite.map((item) => item._id);
    ids?.length && callDeleteForArray(ids);
  };
  const onReadingEditComplete = (data) => {
    const payload = [
      {
        _id: data?.newData?._id,
        Beginning_Inventory_Volume: data?.newData?.Beginning_Inventory_Volume,
        Actual_Ending_Inventory_Volume:
          data?.newData?.Actual_Ending_Inventory_Volume,
        Sales: data?.newData?.Sales,
        Deliveries: data?.newData?.Deliveries,
      },
    ];

    FuelMappingService.fuelsystemupdate(data?.newData?._id, payload)
      .pipe(errorHandler("Update Reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Updated Successfully",
            })
          );
          refreshTableAlongWithFilter();
        }
      });
  };
  const uniqueStates = [...new Set(sites?.map((facility) => facility.state))];
  const stateOptions = uniqueStates.map((state) => ({
    label: state,
    value: state,
  }));
  const numberEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
      />
    );
  };
  const actionColumn = (rowData, columnProps) => {
    return columnProps.rowEditor.editing ? (
      <>
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-text p-button-success"
          onClick={columnProps.rowEditor.onSaveClick}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={columnProps.rowEditor.onCancelClick}
        />
      </>
    ) : (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-secondary"
          onClick={columnProps.rowEditor.onInitClick}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={() => handleReadingDeleteClick(rowData)}
        />
      </>
    );
  };
  function replaceCommasWithSpaces(input) {
    if (typeof input === "string") {
      return input.split(",").join(" ");
    }
    return input;
  }
  const exportCSV = () => {
    const csvContent = [];
    filteredData?.forEach((parentRow) => {
      csvContent.push(["Site Name"]);
      csvContent.push([getNameByCode(parentRow?.parent.site_id)]);
      csvContent.push([
        "",
        "Tank Number",
        "Product",
        "Date",
        "Beginning Inventory Level",
        "Beginning Inventory Volume",
        "Actual Ending Inventory Volume",
        "Ending Inventory Difference",
        "Calculated Ending Inventory",
        "Sales",
        "Deliveries",
        "Water Level",
      ]);
      parentRow.parent.child.forEach((childRow) => {
        csvContent.push([
          "",
          childRow?.Tank_Number,
          childRow.Product,
          formatDate(childRow.Date),
          formatDate(childRow?.Beginning_Inventory_Date),
          formatNumber(childRow?.Beginning_Inventory_Level),
          childRow?.Beginning_Inventory_Volume,
          childRow?.Actual_Ending_Inventory_Volume,
          formatNumber(childRow?.Ending_Inventory_Difference),
          formatNumber(childRow?.Calculated_Ending_Inventory),
          formatNumber(childRow.Sales),
          formatNumber(childRow.Deliveries),
          childRow?.Water_Level,
        ]);
      });
      csvContent.push([]);
    });

    const csvString = csvContent.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "fuel-system-lines.csv";
    link.click();
  };
  const headerElement = (
    <div className="fw-semibold mx-4 mt-3">{`Create Fuel System Lines`}</div>
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${year}/${month}/${day}`;
  };
  const formatDatePickerDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  const formatNumber = (value) => {
    let newVal = value;
    if (typeof value !== "number") {
      newVal = Number(value);
    }
    return newVal ? newVal?.toFixed(2) : "0.00";
  };
  const GetFilteredByParameters = (value) => {
    let filteredData = [...value];

    if (selectedFacilities?.length) {
      filteredData = filteredData.filter((item) =>
        selectedFacilities.includes(item?.parent?.site_id)
      );
    }
    if (selectedStates?.length) {
      filteredData = filteredData.filter((item) =>
        selectedStates.includes(item?.parent?.state)
      );
    }
    if (statusValue) {
      if (statusValue != "All") {
        filteredData = filteredData.filter(
          (item) =>
            statusValue?.toLowerCase() == item?.parent?.result?.toLowerCase()
        );
      }
    }
    return filteredData;
  };
  const onFilter = () => {
    if (
      filters.dates?.length !== 2 &&
      !selectedFacilities?.length &&
      !selectedStates?.length &&
      !statusValue
    ) {
      setFilteredData(fuelMapping);
      return;
    }
    if (filters.dates && filters.dates.length === 2) {
      const payload = {
        client_id: profile.client,
        site_id: profile.sites,
        start_date: formatDatePickerDate(filters.dates[0]),
        end_date: formatDatePickerDate(filters.dates[1]),
      };
      commonService.startLoading();
      RolesService.getFuelLineReadings(payload)
        .pipe(errorHandler("Get Fuel Mapping"))
        .subscribe((response) => {
          if (response?.data) {
            const data = FormateAPIData(response?.data);
            setFuelMapping(data);
            const newData = GetFilteredByParameters(data);
            setFilteredData(newData);
          }
          commonService.stopLoading();
        });
      return;
    } else {
      const newData = GetFilteredByParameters(fuelMapping);
      setFilteredData(newData);
    }
  };
  const refreshTableAlongWithFilter = () => {
    let myPayload = {
      client_id: profile.client,
      site_id: profile.sites,
    };
    if (filters.dates && filters.dates.length === 2) {
      myPayload = {
        client_id: profile.client,
        site_id: profile.sites,
        start_date: formatDatePickerDate(filters.dates[0]),
        end_date: formatDatePickerDate(filters.dates[1]),
      };
    }
    commonService.startLoading();
    RolesService.getFuelLineReadings(myPayload)
      .pipe(errorHandler("Get Fuel Mapping"))
      .subscribe((response) => {
        if (response?.data) {
          const data = FormateAPIData(response?.data);
          setFuelMapping(data);
          const newData = GetFilteredByParameters(data);
          setFilteredData(newData);
        }
        commonService.stopLoading();
      });
  };
  const rowExpansionTemplate = (data) => {
    return (
      <div>
        <div className="d-flex flex-wrap justify-content-end grid_buttons gap-2">
          <Button label="New Reading" text onClick={() => setFormData({ client_id: "", site_id: data?.parent?.site_id })} />
          <Button
            label="Delete"
            text
            className="delete-button"
            disabled={
              !selectedProducts[data.id]?.length ||
              selectedProducts[data.id]?.length < 2
            }
            onClick={() => handleBulkDeleteBySite(selectedProducts[data.id])}
          />
        </div>
        <DataTable
          selectionMode={"checkbox"}
          value={data.parent.child || []}
          id="fuel-lines-grid"
          dataKey={data._id}
          selection={selectedProducts[data.id]}
          showSelectAll={false}
          editMode="row"
          onRowEditComplete={onReadingEditComplete}
          scrollable
          scrollHeight={"250px"}
          onSelectionChange={(e) =>
            setSelectedProducts({ ...selectedProducts, [data.id]: e.value })
          }
          className="child-fuel-grid rounded border flex-grow-1"
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "2rem" }}
            header={"Selected"}
          ></Column>
          <Column field="Tank_Number" header="Tank Number" />
          <Column field="Product" header="Product" />
          <Column
            field="Date"
            header="Date"
            body={(rowData) => formatDate(rowData.Date)}
          />
          {/* <Column
            field="Beginning_Inventory_Date"
            header="Beginning Inventory Date"
            body={(rowData) => formatDate(rowData?.Beginning_Inventory_Date)}
          /> */}
          <Column
            field="Beginning_Inventory_Level"
            header="Beginning Inventory Level"
            body={(rowData) =>
              formatNumber(rowData?.Beginning_Inventory_Level)
            }
          ></Column>
          <Column
            field="Beginning_Inventory_Volume"
            header="Beginning Inventory Volume"
            editor={numberEditor}
          ></Column>
          <Column
            field="Actual_Ending_Inventory_Volume"
            header="Actual Ending Inventory Volume"
            editor={numberEditor}
          ></Column>
          <Column
            field="Ending_Inventory_Difference"
            header="Ending Inventory Difference"
            body={(rowData) =>
              formatNumber(rowData?.Ending_Inventory_Difference)
            }
          ></Column>
          <Column
            field="Calculated_Ending_Inventory"
            header="Calculated Ending Inventory"
            body={(rowData) =>
              formatNumber(rowData?.Calculated_Ending_Inventory)
            }
          ></Column>
          <Column
            field="Sales"
            header="Sales"
            body={(rowData) => formatNumber(rowData.Sales)}
            editor={numberEditor}
          ></Column>
          <Column
            field="Deliveries"
            header="Deliveries"
            editor={numberEditor}
            body={(rowData) => formatNumber(rowData.Deliveries)}
          ></Column>
          <Column field="Water_Level" header="Water Level" />
          <Column
            rowEditor
            header={"Actions"}
            align={"center"}
            body={actionColumn}
          />
        </DataTable>
      </div>
    );
  };
  const onRowToggle = (e) => {
    if (expandedRow && expandedRow.id == e.data.id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(e.data);
    }
    setSelectedProducts([]);
  };
  const allowExpansion = (rowData) => {
    return rowData?.parent?.child?.length > 0;
  };
  const siteNameBody = (rowData) => {
    const siteName = getNameByCode(rowData?.parent.site_id);
    return <div>{siteName}</div>;
  };
  const handleImport = (values) => {
    commonService.startLoading();
    const formData = new FormData();
    formData.append("file", values.file);
    formData.append("site_id", values.site_id);
    FuelMappingService.import(formData).pipe(errorHandler("Import Fuel Mapping")).subscribe((response) => {
      if (response?.data) {
        // getFuelMapping();
        setImportDialog(false);
        dispatch(
          slice.actions.updateToast({
            severity: "success",
            message: "Fuel system Readings imported.",
          })
        );
        commonService.stopLoading()
      }
    })
    FuelMappingService.createNew(formData)
      .pipe(errorHandler("Import Fuel Mapping"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Imported Fuel Mapping",
            })
          );
          setImportDialog(false)
          refreshTableAlongWithFilter();
          commonService.stopLoading();
        }
      });
  };
  const handleCalculateVarianceClick = (values) => {
    commonService.startLoading();
    const payload = {
      client_id: profile.client,
      site_id: values.site_id,
      start_date: formatDatePickerDate(values.start_date),
      end_date: formatDatePickerDate(values.end_date),
    };
    FuelMappingService.calculate(payload).pipe(errorHandler("calculate variance")).subscribe((response) => {
      if (response?.data) {
        getFuelMapping();
        setCalculateVarianceDialog(false);
        dispatch(
          slice.actions.updateToast({
            severity: "success",
            message: response?.data?.message || "variance calculated.",
          })
        );
        commonService.stopLoading()
      }
    })
  };
  return (
    <div className="d-flex flex-column h-100 p-3 gap-3">
      {/* <div className="fuel-title">Fuel System Lines</div> */}
      <div className="d-flex align-items-end flex-column flex-sm-row">
        <div
          className="d-flex flex-row col-12 col-sm-8 flex-grow-1 flex-wrap gap-3"
          style={{ alignItems: "flex-end" }}
        >
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <label htmlFor="site">Site Name</label>
            <MultiSelect
              value={selectedFacilities}
              options={sites}
              loading={!sites.length}
              optionLabel="label"
              onChange={(e) => setSelectedFacilities(e.value)}
              placeholder="Select Facilities"
            />
          </div>
        
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <label htmlFor="date">Date Range</label>
            <Calendar
              value={filters.dates}
              onChange={({ target: { name, value } }) =>
                setFilters({ ...filters, [name]: value })
              }
              onHide={() =>
                (!filters.dates?.[0] || !filters.dates?.[1]) &&
                setFilters({ ...filters, dates: null })
              }
              selectionMode="range"
              readOnlyInput
              hideOnRangeSelection
              placeholder="Date range"
              id="dates"
              name="dates"
              showButtonBar
            />
          </div>
          <div
            className="d-flex flex-column gap-1 col-12 col-md-6 flex-wrap justify-content-end grid_buttonsWObg"
            style={{ height: "47px", alignContent: "flex-end", width: "100%" }}
          >
            <Button label="Filter" className="styledBtn" onClick={onFilter} text />
            <Button label="Import" className="styledBtn" text onClick={() => setImportDialog(true)} />
            <Button label="Export" className="styledBtn" text onClick={exportCSV} />
            <Button label="Calculate Variance" className="styledBtn" text onClick={() => setCalculateVarianceDialog(true)} />
          </div>
        </div>
        <div>
          <Dialog
            appendTo="self"
            visible={importDialog}
            modal
            header={
              <div className="fw-semibold mx-4 mt-3">
                {`Import Fuel System Readings`}
              </div>
            }
            onHide={() => setImportDialog(false)}
          >
            <FuelSystemReadingsImport
              sites={sites}
              onCancel={() => setImportDialog(false)}
              onSubmit={handleImport}
            />
          </Dialog>
          <Dialog
            appendTo="self"
            visible={calculateVarianceDialog}
            modal
            header={
              <div className="fw-semibold mx-4 mt-3">
                {`Calculate Variance`}
              </div>
            }
            onHide={() => setCalculateVarianceDialog(false)}
          >
            <CalculateVarianceDialog
              sites={sites}
              onCancel={() => setCalculateVarianceDialog(false)}
              onCalculate={handleCalculateVarianceClick}
            />
          </Dialog>
        </div>
      </div>
      <Dialog
        appendTo="self"
        visible={!!formData}
        modal
        header={headerElement}
        onHide={() => setFormData(null)}
      >
        <FuelSystemLinesForm
          editData={formData}
          onSave={(data) => createNewReading(data)}
          onCancel={() => setFormData(null)}
        />
      </Dialog>
      <Toast ref={toast} />

      <div style={{ maxWidth: maxWidth }}>
        <DataTable
          id="fuel-lines-grid"
          ref={dt}
          dataKey="parent.id"
          showGridlines
          scrollable
          loading={isLoading}
          scrollHeight={window.innerHeight - 220}
          value={filteredData}
          size="large"
          className="fuel-system-grid rounded border flex-grow-1"
          rows={10}
          paginator
          totalRecords={fuelMapping.length}
          pageLinkSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          reorderableColumns
          resizableColumns
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={[expandedRow]}
          onRowExpand={onRowToggle}
          onRowCollapse={onRowToggle}
          emptyMessage="No records found."
        >
          <Column expander={allowExpansion} style={{ width: "3rem" }} />
          <Column
            body={siteNameBody}
            headerStyle={{ textAlign: "center" }}
            bodyStyle={{ textAlign: "center" }}
            header="Site Name"
          />
        </DataTable>
      </div>
    </div>
  );
};

export default FuelSystemLinesReadings;
